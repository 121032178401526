// Buttons
%j-button--plain {
    display: inline;
    padding: 0;
    border: none;
    border-radius: 0;
    color: $font-color;
    text-decoration: none;
    text-shadow: none;
    cursor: pointer;
    background: none;
    box-shadow: none;
    outline: none;
    transition: all 0.1s;
    &:focus,
    &:hover,
    &:active {
        background: none;
        box-shadow: none;
    }
    &:hover {
        color: $plain-button-hover;
    }
    &:active {
        color: $plain-button-active;
    }
    &[disabled]:disabled {
        color: $plain-button-disabled;
        opacity: 1;
        background: none;
    }
}
.j-button--plain {
    @extend %j-button--plain;
}

.j-button--plain-inverse {
    @extend %j-button--plain;
    color: $main-button-font-color;
    &:hover,
    &:focus {
        border-bottom-color: $main-button-border-hover;
    }
    &:active {
        //transform: translate(0,5px);
        border-bottom: 1px solid $main-button-border-hover;
    }
}

.j-button--footer {
    @extend %j-button--plain;
    color: $font-color-inverse;
    &:hover,
    &:focus {
        color: $plain-button-active;
    }
    &:active {
        color: $plain-button-hover;
    }
}

.j-button {
    position: relative;
    display: inline-block;
    padding: 10px 40px;
    border: 0;
    border-bottom: 5px solid $main-button-color-border;
    border-radius: 10px;
    color: $main-button-font-color;
    cursor: pointer;
    font-family: $button-font;
    font-size: 2em;
    font-weight: 500;
    text-align: center;
    text-shadow: 0 -2px $main-button-color-border;
    text-decoration: none;
    background-image: none;
    background-color: $main-button-color;
    transition: all 0.1s;
    outline: none;

    &:hover,
    &:focus {
        border-bottom-color: $main-button-border-hover;
    }
    &:active {
        transform: translate(0,5px);
        border-bottom: 1px solid $main-button-border-hover;
    }
    &[disabled]:disabled {
        padding: 10px 18px;
        box-sizing: border-box;
        color: $main-button-disabled-font;
        font-size: 0.857em;
        font-weight: 400;
        background-color: $main-button-disabled;
        box-shadow: none;
    }
}
