// Mixins

// -------------------------
// Font smoothing mixin
// -------------------------

@mixin font-smoothing($value: antialiased) {
    @if $value == antialiased {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    @else {
      -webkit-font-smoothing: subpixel-antialiased;
      -moz-osx-font-smoothing: auto;
    }
  }
