@import 'mixins';

.j-main-sizing {
    margin: 0 auto;
    max-width: 980px;
    text-align: center;
}

body {
    @include font-smoothing;
    background-color: $background-color;
    color: $font-color;
    font-family: $font;
    font-size: $base-font-size;
}

.j-borders {
    border-right: $border-style;
    border-left: $border-style;
}

.j-main {
    display: block;
}

.j-table {
    display: table;
    margin: 0 auto;
}

.j-home {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    background-color: $overlay-background;
    font-family: $font-sans;
    @media (min-width: #{$medium-breakpoint}) {
        flex-wrap: nowrap;
    }
    &__experiences {
        padding: 20px 40px;
        font-size: 2em;
    }
    &__experience-item {
        display: block;
        position: relative;
        padding: 20px 0;
        line-height: 1.5;
        z-index: 1;
    }
    &__desc {
        flex-grow: 1;
        width: 50%;
        box-sizing: border-box;
        padding: 20px;
        text-align: left;
        @media (min-width: #{$medium-breakpoint}) {

        }
    }
    &__highlight {
        color: $font-color-highlight;
        position: relative;
        display: inline-block;
        z-index: -1;
        box-shadow: inset 0 -20px 0 0 $font-color-bkgd, 29px 11px 0px -11px $font-color-bkgd;
        &:before,
        &:after {
            content: " ";
        }
    }
    &__pic-container {
        position: relative;
        flex-grow: 1;
        width: 50%;
        height: $content-height;
    }
    &__pic {
        width: 100%;
        height: 100%;
        object-fit: cover;
        box-sizing: border-box;
        box-shadow: 0 0 1px 1px $border-color;
    }
    &__pic-atr {
        position: absolute;
        right: 9px;
        bottom: 0;
        font-size: 0.8em;
    }
}

.j-about {
    min-height: 600px; 
}
