// Navigation
.j-nav {
    margin: 0 0 0 auto;
    &__link {
        margin: 1em;
        font-size: 1.5em;
        &:last-child {
            margin-right: 0;
        }
    }
    &__container {
        display: block;
        @media (min-width: #{$medium-breakpoint}) {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }
    }
    &__desktop {
        padding: 1em;
    }
}
