
.j-social-icons {
    height: 40px;
    width: 40px;
    fill: currentColor;
}

.svg-icon {
    height: 40px;
    width: 40px;
    fill: currentColor;
}
