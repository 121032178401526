//==========================================================================
// Colors
//==========================================================================

// OLD THEME - Primary
$very-dark-grayish-cyan:        #606564;
$very-dark-cyan:                #153d39;
$greenish-cyan-alpha:           rgba(127, 199, 175, 0.2);

$light-grayish-cyan:            #e4f2f3;
$slightly-desaturated-cyan:     #76bcc2;
$dark-moderate-cyan:            #31766f; 
$slighty-desaturated-green:     #a4b880;
$slighty-desaturated-violet:    #9480b8;
$moderate-orange:               #d56e44;
$moderate-yellow:               #d5b744;
$very-soft-yellow:              #e5de8a;
$dark-cyan-alpha:               rgba(21, 61, 57, 0.3);

//
// NEW THEME - PRIMARY CITY OF GOD
//
$mostly-desaturated-dark-blue:  #536C8B;
$steel-gray:                    #858DA0;
$chocolate-brown:               #705B4A;
$beige:                         #B8AB89;
$light-grayish-orange:          #F1DBC4;
$greenish-cyan:                 #7FC7AF;


$dark-blue:                     #56538b;
$mostly-desaturated-dark-cyan:  #53888b;
$mostly-desaturated-violet:     #56538b;

// Primary Picture Highlight Color
$vivid-red:                      #e91e43;


//==========================================================================
// Grays
//==========================================================================

$white:                         #FFFFFF;
$gray:                          #BBBBBB;
$light-gray:                    #f4f4f4;
$very-light-gray:               #e4e4e4;

$very-dark-gray:                #333333;
$black-alpha:                   rgba(0, 0, 0, 0.5);
$black:                         #000000;

// ==========================================================================
// Functional Colors
// ==========================================================================

// Fonts
$font-color:                    $light-gray;
$font-color-inverse:            $very-light-gray;
$font-color-highlight:          $light-grayish-orange;
$font-color-bkgd:               $mostly-desaturated-dark-blue;
$heading-font-color:            $very-dark-cyan;

// Backgrounds
$background-color:              $light-gray;
$header-background:             $very-dark-gray;
$footer-background:             $very-dark-gray;
$overlay-background:            $steel-gray;

// Highlight
$highlight-color:               $greenish-cyan;

// Borders and Dividers
$divider-color:                 $gray;
$border-color:                  $black-alpha;
$highlight-border:              $gray;

$border-style:                  10px solid $highlight-border;

$box-shadow-style:              0 0 4px 0 $border-color;
$box-shadow-style-top:          0 1px 4px 0 $border-color;
$box-shadow-style-bottom:       0 -1px 4px 0 $border-color;

// Buttons
$main-button-color:             $mostly-desaturated-dark-blue;
$main-button-hover:             $dark-blue;
$main-button-font-color:        $light-gray;
$main-button-color-border:      $mostly-desaturated-violet;
$main-button-border-hover:      $mostly-desaturated-dark-cyan;
$main-button-disabled:          $very-light-gray;
$main-button-disabled-font:     $dark-cyan-alpha;


$plain-button-hover:            $dark-moderate-cyan;
$plain-button-active:           $light-grayish-cyan;
$plain-button-disabled:         $gray;

// Inputs
$input-background-focus:        $light-gray;

// Icons
$icon-color:                    $dark-moderate-cyan;

// Spacing
$bottom-spacing:                2em;

// Z-indexing
$zindex: (
  modal     : 900, 
  overlay   : 800,
  dropdown  : 700,
  header    : 600,
  footer    : 500
);

// ==========================================================================
// Breakpoint widths
// ==========================================================================

$medium-breakpoint:             760px;

// ==========================================================================
// Fonts
// ==========================================================================

$base-font-size:                14px;
$font:                          'Roboto Slab', serif;
$font-sans:                     'Source Sans Pro', sans-serif;
$heading-font:                   Montserrat;
$button-font:                   'Roboto Slab', serif;
$icon-font:                     'Material Icons';

// ==========================================================================
// Calcs
// ==========================================================================

$header-height:                 64px;
$footer-height:                 200px;
$content-height:                calc(100vh - $header-height - 1px - $footer-height);
