
.j-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid $border-color;
    height: $footer-height;
    width: 100%;
    bottom: $bottom-spacing;
    box-shadow: $box-shadow-style-top;
    background-color: $footer-background;
    &__link {
        display: inline-block;
        padding: 2em 1em;
    }
}
